<template>
    <div class="wrapper" id="error">
      404- OK
    </div>
  </template>
  
  <script lang="js">
  export default {
    name: "Error",
    data() {
      return {};
    },
    computed: {},
    methods: {},
  };
  </script>