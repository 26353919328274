<template>
  <div>
    <div id="container">

      <div id="buttons">
        <input id="roomid">
        <button id="startButton" disabled>ENTRY</button>
        <button id="sendButton">Send</button>
        <button id="closeButton">Stop</button>
      </div>

      <div id="sendReceive">
        <div id="send">
          <h2>Send</h2>
          <textarea id="dataChannelSend" placeholder="Press Start, enter some text, then press Send."></textarea>
        </div>
        <div id="receive">
          <h2>Receive</h2>
          <textarea id="dataChannelReceive"></textarea>
        </div>
      </div>

      <p>Open the sample in two tabs (of the same browser), then click start in the first tab and
        send messages back and forth.</p>
    </div>
  </div>
</template>

<script>

import "../lib/adapter-latest.js"
import "../lib/main.js"

export default {
  data() {
    return {
      room: '',
      connected: false,
      peerConnection: null,
      dataChannel: null,
      ws: null
    };
  },
  async mounted() {
    const webApp = window.Telegram.WebApp
    console.log(webApp)
    console.log(webApp.initData)
    const result = await fetch("https://bots.reneos.com/api", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(webApp.initData)
    })
    const j = await result.json()
    console.log(j)
  }
};
</script>