import { createRouter, createWebHistory } from 'vue-router'

import NotFoundComponent from "./views/Error404.vue"

import Auth from "@/views/Auth.vue"
import Main from "@/views/Main.vue"
import Lobby from "@/views/Lobby.vue"
import Room from "@/views/Room.vue"
import Cabinet from "@/views/Cabinet.vue"

const routes = [
	{
		path: '/',
		name:"Auth",
		components:{
			Auth,
			Main
		},
		children: [
			{
				path: '/',
			  	name: 'Lobby',
			  	component:Lobby, // Компонент для комнаты
			},
			{
			  path: 'rooms/:room?',
			  name: 'Room',
			  component: Room, // Компонент для комнаты
			},
			{
			  path: 'cabinet',
			  name: 'Cabinet',
			  component: Cabinet, // Компонент для кабинета
			}
		  ],
	},
	
	{
		path: '/:room',
		name:"Main",
		component: () => import('./views/Main.vue')
	},
	//Error
	{
		path: '/404', name: 'Error', component: NotFoundComponent,
	},
	{
		path: "/:catchAll(.*)", redirect: "/404"
	}
]

const router = createRouter({
	history: createWebHistory('/'),
	routes
})

export default router
