<template>
    <div class="container">
        <input id="roomId" v-model="room"><button @click="entryRoom">Entry</button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            room: ""
        }
    },
    methods: {
        async entryRoom() {
            this.$router.push({
                name:"Room",params:{room:this.room}
            })
        }
    }
}
</script>
<style scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>