let _wss

function ConnectWs() {
  _wss = new WebSocket('https://localhost:3000?room=room1', Math.random().toString(16))
  _wss.addEventListener("message", async (event) => {
    const data = JSON.parse(event.data);
    switch (data.type) {
      case 'offer':
        handleOffer(data);
        break;
      case 'answer':
        handleAnswer(data);
        break;
      case 'candidate':
        handleCandidate(data);
        break;
      case 'ready':
        // A second tab joined. This tab will enable the start button unless in a call already.
        if (pc) {
          console.log('already in call, ignoring');
          return;
        }
        //startButton.disabled = false;
        break;
      case 'bye':
        if (pc) {
          hangup();
        }
        break;
      default:
        console.log('unhandled', e);
        break;
    }
  });
  _wss.addEventListener("open", (a) => {
    startButton.disabled = false
  })
}


const startButton = document.getElementById('startButton') || {}
const closeButton = document.getElementById('closeButton') || {}
const sendButton = document.getElementById('sendButton') || {}
const roomInput = document.querySelector('#roomid') || {}

//sendButton.onclick = sendData;

const dataChannelSend = document.querySelector('textarea#dataChannelSend');
const dataChannelReceive = document.querySelector('textarea#dataChannelReceive');

let pc;
let sendChannel;
let receiveChannel;

startButton.onclick = async () => {
  _wss.send(JSON.stringify({ type: 'ready' }))

  startButton.disabled = true;
  closeButton.disabled = false;

  await createPeerConnection();
  sendChannel = pc.createDataChannel('sendDataChannel');
  sendChannel.onopen = onSendChannelStateChange;
  sendChannel.onmessage = onSendChannelMessageCallback;
  sendChannel.onclose = onSendChannelStateChange;

  const offer = await pc.createOffer();
  _wss.send(JSON.stringify({ type: 'offer', sdp: offer.sdp }));
  await pc.setLocalDescription(offer);
};

closeButton.onclick = async () => {
  hangup();
  _wss.send(JSON.stringify({ type: 'bye' }));
};

async function hangup() {
  if (pc) {
    pc.close();
    pc = null;
  }
  sendChannel = null;
  receiveChannel = null;
  console.log('Closed peer connections');
  startButton.disabled = false;
  sendButton.disabled = true;
  closeButton.disabled = true;
  dataChannelSend.value = '';
  dataChannelReceive.value = '';
  dataChannelSend.disabled = true;
};

function createPeerConnection() {
  pc = new RTCPeerConnection();
  pc.onicecandidate = e => {
    const message = {
      type: 'candidate',
      candidate: null,
    };
    if (e.candidate) {
      message.candidate = e.candidate.candidate;
      message.sdpMid = e.candidate.sdpMid;
      message.sdpMLineIndex = e.candidate.sdpMLineIndex;
    }
    _wss.send(JSON.stringify(message));
  };
}

async function handleOffer(offer) {
  console.log(offer)
  if (pc) {
    console.error('existing peerconnection');
    return;
  }
  await createPeerConnection();
  pc.ondatachannel = receiveChannelCallback;
  await pc.setRemoteDescription(offer);

  const answer = await pc.createAnswer();
  _wss.send(JSON.stringify({ type: 'answer', sdp: answer.sdp }));
  await pc.setLocalDescription(answer);
}

async function handleAnswer(answer) {
  if (!pc) {
    console.error('no peerconnection');
    return;
  }
  await pc.setRemoteDescription(answer);
}

async function handleCandidate(candidate) {
  if (!pc) {
    console.error('no peerconnection');
    return;
  }
  if (!candidate.candidate) {
    await pc.addIceCandidate(null);
  } else {
    await pc.addIceCandidate(candidate);
  }
}

function sendData() {
  const data = dataChannelSend.value;
  if (sendChannel) {
    sendChannel.send(data);
  } else {
    receiveChannel.send(data);
  }
  console.log('Sent Data: ' + data);
}

function receiveChannelCallback(event) {
  console.log('Receive Channel Callback');
  receiveChannel = event.channel;
  receiveChannel.onmessage = onReceiveChannelMessageCallback;
  receiveChannel.onopen = onReceiveChannelStateChange;
  receiveChannel.onclose = onReceiveChannelStateChange;
}

function onReceiveChannelMessageCallback(event) {
  console.log('Received Message');
  dataChannelReceive.value = event.data;
}

function onSendChannelMessageCallback(event) {
  console.log('Received Message');
  dataChannelReceive.value = event.data;
}

function onSendChannelStateChange() {
  const readyState = sendChannel.readyState;
  console.log('Send channel state is: ' + readyState);
  if (readyState === 'open') {
    dataChannelSend.disabled = false;
    dataChannelSend.focus();
    sendButton.disabled = false;
    closeButton.disabled = false;
  } else {
    dataChannelSend.disabled = true;
    sendButton.disabled = true;
    closeButton.disabled = true;
  }
}

function onReceiveChannelStateChange() {
  const readyState = receiveChannel.readyState;
  console.log(`Receive channel state is: ${readyState}`);
  if (readyState === 'open') {
    dataChannelSend.disabled = false;
    sendButton.disabled = false;
    closeButton.disabled = false;
  } else {
    dataChannelSend.disabled = true;
    sendButton.disabled = true;
    closeButton.disabled = true;
  }
}