import { reactive  } from 'vue'

/**
 * Пользователь авторизован пока id не равно null
 */
const _data = reactive({
    id:null,
    async load(){
    }
})

export default _data