<template>
    <div><button @click="auth">LOGIN</button></div>
</template>
<script>
export default {
    methods:{
        auth(){
            this.$app.user.id = "alskjdlakjsd"
        }
    }
}
</script>