<template>
    <div class="main-container">
        
        <router-view class="mainblock"></router-view> <!-- Отображение дочерних маршрутов Lobby, Room и Cabinet -->
    </div>
</template>
<script>
import UserView from "@/composables/userview.vue"

export default {
    data() {
        return {
            roomId: ""
        }
    },
    computed: {
        room() {
            return this.$app.room
        },
        users() {
            return this.$app.room.users
        },
        self() {
            return this.$app.room.self
        },

    },
    methods: {
        moveTo(p,params={}){
            this.$router.push({
                name:p,params
            })
        },
        /*async connect() {
            try {
                this.$app.room.id = this.roomId
                await this.$app.room.connect()
            } catch (error) {
                console.warn(error)
            }
        }*/
    },
    mounted() {
        /*const params = new URLSearchParams(location.search)
        params.set('token','kjshaskjdhakjshd')
        console.log(params)
        const roomId = params.get("room")
        if (roomId) {
            const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
                const [name, value] = cookie.split('=');
                acc[name] = decodeURIComponent(value);
                return acc;
            }, {})
            this.roomId = roomId
            this.connect()
        }*/
    },
    components: { UserView }
}
</script>
<style lang="scss">
.main-container {
    height: 100%;
    width: 100%;
    .mainblock{
        height: 100%;
        width: 100%;
    }
    .lobby {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: space-around;
    }

    .room-view {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;

        .self-container {
            width: 100%;
            min-height: 30px;
        }
    }


}
</style>