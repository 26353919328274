import { reactive } from 'vue'

const _data = reactive({
    id: null,
    connected: false,
    self: {},
    users: [],
    socket: null,
    localVideo: null,
    remoteVideos: [],
    peers: {},
    localStream: null,
    host: "binbot.reneos.com",
    port: 443,
    path: "/phone",
    messages:[],
    pendingCandidates: {},
    async connect() {
        const resp = { key: Math.random().toString(16).slice(2) };
        _data.socket = new WebSocket(`ws://${_data.host}:${_data.port}${_data.path}?k=${resp.key}`, resp.key);

        // Обработка сообщений от сервера
        _data.socket.onmessage = (message) => {
            const data = JSON.parse(message.data);
            console.log('message ' + data.type);
            if (data.type === 'offer') {
                _data.handleOffer(data.offer, data.userId);
            } else if (data.type === 'answer') {
                _data.handleAnswer(data.answer, data.userId);
            } else if (data.type === 'candidate') {
                _data.handleCandidate(data.candidate, data.userId);
            } else if (data.type === 'user-connected') {
                _data.connectToNewUser(data.userId);
            } else if (data.type === 'user-disconnected') {
                _data.removePeer(data.userId);
            }
        };

        try {
            await new Promise((resolve, reject) => {
                _data.socket.addEventListener('open', resolve);
                _data.socket.addEventListener('error', reject);
            });
        } catch (error) {
            console.log('Error');
            console.warn(error);
            return false;
        }

        console.log('connected');
        const devices = await _data.checkDevices();
        await _data.openDevices(devices);
        console.log('Send join');
        _data.socket.send(JSON.stringify({ type: 'join', room: _data.id }));
        _data.connected=true
    },
    async checkDevices() {
        const devices = await navigator.mediaDevices.enumerateDevices();
        let hasVideoInput = false;
        let hasAudioInput = false;

        devices.forEach(device => {
            if (device.kind === 'videoinput') {
                hasVideoInput = true;
            } else if (device.kind === 'audioinput') {
                hasAudioInput = true;
            }
        });
        return {
            audio: hasAudioInput,
            video: hasVideoInput
        };
    },
    async openDevices(devices) {
        const stream = await navigator.mediaDevices.getUserMedia(devices);
        _data.localStream = stream;
    },
    connectToNewUser(userId) {
        console.log(`Connect userId`)
        const peerConnection = new RTCPeerConnection();

        // Добавляем локальные медиа-треки
        _data.localStream?.getTracks().forEach(track => peerConnection.addTrack(track, _data.localStream));

        peerConnection.onicecandidate = event => {
            if (event.candidate) {
                console.log('Send candidate');
                _data.socket.send(JSON.stringify({ type: 'candidate', candidate: event.candidate, userId }));
            }
        };

        peerConnection.ontrack = event => {
            _data.remoteVideos.push({
                src: event.streams[0],
                autoplay: true
            });
        };

        peerConnection.createOffer().then(offer => {
            peerConnection.setLocalDescription(offer);
            console.log('Send offer');
            _data.socket.send(JSON.stringify({ type: 'offer', offer: offer, userId }));
        });

        _data.peers[userId] = peerConnection;
    },
    handleCandidate(candidate, userId) {
        console.log('handleCandidate from ' + userId);
        const peerConnection = _data.peers[userId];
        if (peerConnection) {
            if (peerConnection.remoteDescription && peerConnection.remoteDescription.type) {
                // Если remoteDescription установлена, добавляем кандидата сразу
                peerConnection.addIceCandidate(new RTCIceCandidate(candidate)).catch(e => console.error(e));
            } else {
                // Иначе сохраняем кандидата до момента, когда remoteDescription будет установлена
                if (!_data.pendingCandidates[userId]) {
                    _data.pendingCandidates[userId] = [];
                }
                _data.pendingCandidates[userId].push(candidate);
                console.log(`Stored candidate for userId ${userId}`);
            }
        }
    },

    handleOffer(offer, userId) {
        const peerConnection = new RTCPeerConnection();
        peerConnection.setRemoteDescription(new RTCSessionDescription(offer)).then(() => {
            // Добавляем кандидаты, если они были получены до установки remoteDescription
            if (_data.pendingCandidates[userId]) {
                _data.pendingCandidates[userId].forEach(candidate => {
                    peerConnection.addIceCandidate(new RTCIceCandidate(candidate)).catch(e => console.error(e));
                });
                delete _data.pendingCandidates[userId]; // Очищаем после добавления
            }
        });

        _data.localStream?.getTracks().forEach(track => peerConnection.addTrack(track, _data.localStream));

        peerConnection.onicecandidate = event => {
            if (event.candidate) {
                _data.socket.send(JSON.stringify({ type: 'candidate', candidate: event.candidate, userId }));
            }
        };

        peerConnection.ontrack = event => {
            console.log(event)
            _data.remoteVideos.push({
                src: event.streams[0],
                autoplay: true
            });
        };

        peerConnection.createAnswer().then(answer => {
            peerConnection.setLocalDescription(answer);
            console.log('Send answer');
            _data.socket.send(JSON.stringify({ type: 'answer', answer: answer, userId }));
        });

        _data.peers[userId] = peerConnection;
    },

    handleAnswer(answer, userId) {
        const peerConnection = _data.peers[userId];
        if (peerConnection) {
            peerConnection.setRemoteDescription(new RTCSessionDescription(answer)).then(() => {
                // Добавляем отложенные кандидаты
                if (_data.pendingCandidates[userId]) {
                    _data.pendingCandidates[userId].forEach(candidate => {
                        peerConnection.addIceCandidate(new RTCIceCandidate(candidate)).catch(e => console.error(e));
                    });
                    delete _data.pendingCandidates[userId];
                }
            }).catch(e => console.error(e));
        }
    },
    removePeer(userId) {
        if (_data.peers[userId]) {
            _data.peers[userId].close();
            delete _data.peers[userId];
        }
    }
});

export default _data;
