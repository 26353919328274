import { createApp } from 'vue'
import { App } from '@reneos/apptools'
import router from '@/router.js'
import '@/styles/app.scss'
import * as Models from '@/app/index.js'

import AppView from '@/views/App.vue'

const a = createApp(AppView)
a.use(vue => {
	vue.config.globalProperties.$app = App.Init(Models)
})
a.use(router)
a.mount('#app')
export {a as app}