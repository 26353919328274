<template>
    <div class="wrapper">
      CABINET
    </div>
  </template>
  
  <script lang="js">
  export default {
    name: "Cabinet",
    data() {
      return {};
    },
    computed: {},
    methods: {},
  };
  </script>