<template>
    <div class="app">
        <router-view v-if="ready" :name="user.id?'Main':'Auth'"></router-view>
        <div v-else>LOAD...</div>
    </div>
</template>
<script>
export default {
    name: "App",
    data() {
        return {
            ready: false
        }
    },
    computed:{
        user(){
            return this.$app.user
        }
    },
    mounted() {
        console.log(this.$app.user)
        this.ready = true
    }
}
</script>